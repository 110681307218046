
/* eslint-disable */
import { defineComponent, ref, PropType, onMounted } from "vue";
import { Sorting, SortOrder, Condition } from "@cloudfun/core";
import { VxeColumnPropTypes, VxeGridInstance, VxeGridProps, VxeGridPropTypes, VxeModalProps, VXETable, VxeTableConstructor, VxeTableDefines, VxeTablePrivateMethods, VxeTablePropTypes } from "vxe-table";
import { ContentLoader } from "vue-content-loader";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export interface GridColumnOptions {
  /**
    * 資料欄位
    */
  field?: string;
  /**
   * 標題
   */
  title?: string;
  /**
   * 寬度
   */
  width?: number;
  /**
   * 排序功能
   */
  sortable?: boolean;
  /**
   * 格式化顯示內容
   */
  formatter?: (params: {
    cellValue: any;
    column: GridColumnOptions;
    row: any;
  }) => string | number;
}

export interface GridOptions {
  /** 欄未設定。當存在type為checkbox之欄位時將置換選取欄，當存在slots.default之欄位時將置換操作欄 */
  columns: VxeGridPropTypes.Columns;
  /** 非同步設定 */
  promises?: {
    /** 非同步查詢 */
    query?: (params: {
      page: number;
      pageSize: number;
      keyword?: string;
      sortings?: Sorting[];
      condition?: Condition;
    }) => Promise<{ data: any[]; pageCount: number; }>;
    // /** 儲存已變更資料 */
    // save?: (params: {
    //   insertRows?: any[];
    //   updateRows?: any[];
    //   deleteRows?: any[];
    // }) => Promise<void>;
  };
};

const findKeyValue = (keys: string[], row: any) => {
  row = row[keys[0]];
  if (keys.length === 1) return row;

  keys.shift();
  return findKeyValue(keys, row);
}

export default defineComponent({
  name: "CfGrid",
  components: {
    ContentLoader,
    VPagination
  },
  props: {
    /** 所有欄位 */
    columns: {
      type: Object as PropType<GridColumnOptions[]>,
      required: true,
    },
    /** 可提供的承諾 */
    promises: {
      type: Object as PropType<{
        query?: (params: {
          page: number;
          pageSize: number;
          keyword?: string;
          sortings?: Sorting[];
          condition?: Condition;
        }) => Promise<{ data: any[]; pageCount: number }>;
        // save?: (params: {
        //   insertRows?: any[];
        //   updateRows?: any[];
        //   deleteRows?: any[];
        // }) => Promise<void>;
      }>,
    },
  },
  emits: ["cellClick"],
  setup(props, { emit }) {
    const selectedRow = ref(-1);
    const data = ref<any[]>([]);
    const sortings = ref<Sorting[]>([]);
    const loading = ref(false);
    const paginationConfig = {
      currentPage: 1,
      pages: 1
    };
    const loadData = () => {
      if (props.promises?.query) {
        loading.value = true;
        props.promises.query({ page: paginationConfig.currentPage, pageSize: 20, condition: new Condition(), sortings: sortings.value }).then(res => {
          data.value = res.data;
          paginationConfig.pages = res.pageCount;
          
        }).finally(() => {
          loading.value = false;
        });
      }
    }

    onMounted(() => {
      const sortingColumns = props.columns.filter(e => e.field && e.sortable);
      if (sortingColumns.length > 0)
        sortings.value.push(new Sorting(sortingColumns[0].field!, SortOrder.Descending));
      loadData();
    })

    const processCellValue = (row: any, col: GridColumnOptions) => {
      const cellValue = col.field ? findKeyValue(col.field.split('.'), row) : "";
      return col.formatter?.({
        cellValue,
        column: col,
        row
      }) || cellValue
    }

    const cellClick = (row: any, rowIndex: number, column: GridColumnOptions, columnIndex: number) => {
      selectedRow.value = rowIndex;
      emit('cellClick', { row, rowIndex, column, columnIndex });
    }

    const changeSorting = (col: GridColumnOptions) => {
      if (!col.field) return;
      const temp = sortings.value.find(e => e.column === col.field);
      if (temp)
        temp.order = temp.order === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;
      else
        sortings.value.push(new Sorting(col.field, SortOrder.Descending));

      loadData();
    }
    const current: any = ref(null);
    const updateHandler = (e:any)=>{
      paginationConfig.currentPage = e;
      loadData();
    }

    return {
      data,
      SortOrder,
      sortings,
      selectedRow,
      loading,
      changeSorting,
      cellClick,
      loadData,
      processCellValue,
      current,
      paginationConfig,
      updateHandler
    };
  },
  methods: {
    reload() {
      this.loadData();
    }
  }
});

