
import Cloudfun , { defineComponent, ref, reactive, watch, onMounted } from "@cloudfun/core";
import type { Project } from "@/models/data";
import { helper } from "@/utils/helper"
// import SelectBox, { SelectBoxOptions } from "@/components/select-box/Main.vue";
import CfGrid, { GridOptions, GridColumnOptions } from "@/components/grid/Main.vue";
import CfTabs from "@/components/tabs/Main.vue";
import CfSearchBar from "@/components/search-bar/Main.vue";
import CfToolBar from "@/components/tool-bar/Main.vue";
import ConfirmRemoveModal from "@/components/modal/confirm-remove/Main.vue";
import CfModal from "@/components/modal/Main.vue";
import { VxeFormProps } from "vxe-table";
import CfButton from "@/components/button/Main.vue";

export default defineComponent({
  components: {
    // CfTabsBar,
    CfGrid,
    CfToolBar,
    CfTabs,
    CfSearchBar,
    CfModal,
    ConfirmRemoveModal,
    CfButton,
    // SelectBox
  },
  setup() {
    const model = Cloudfun.current?.model;
    const permissions: any = Cloudfun.current?.user.Permissions;
    const grid = ref<any>();
    const selectedRow = ref<Project | null>(null);
    let userStatus = ref<any>([]);
    const gridOptions: GridOptions = {
      columns: [
        { field: "Name", title: "Name", width: 300 },
        { field: "Account", title: "Account", width: 200 },
        { field: "UpdatedTime", title: "Last modified", width: 150, sortable: true, formatter({ row }) { return helper.formatDate(row.UpdatedTime, "yyyy/MM/dd HH:mm") } },
      ],
      promises: {
        query: model
          ? (params) => new Promise((resolve) => {
            if (grid.value.keyword) params.keyword = grid.value.keyword
            params.sortings = params.sortings || [];
            model.dispatch('user/query', params).then(resp => {
              resolve(resp);
            });
          }) : undefined,
      }
    }

    // const onGridCellClick = ({ row, column }: { row: Project, column: GridColumnOptions }) => {
    //   if (column.field === "Name") {
    //     router?.push("/project/" + row.Id);
    //     return;
    //   }
    //   selectedRow.value = row;
    // }

    const onSearch = (value: string) => {
      console.log(value);
      grid.value.keyword = value;
      grid.value.reload();
    }
    const selectorOptions: any = ref([]);
    const tabs: any = ref([])

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Name", title: "Name", span: 12, className: "bg-grayscale-14 text-secondary", itemRender: { name: "$input", props: { placeholder: "Name" } } },
        { field: "Status", title: "Status", span: 12, className: "bg-grayscale-14 text-secondary", itemRender: { name: "$select", 
          options: userStatus }
        },
        { field: "Account", title: "Account", span: 12, className: "bg-grayscale-14 text-secondary", itemRender: { name: "$input", props: { placeholder: "Account" } } },
        { field: "Password", title: "Password", span: 12, className: "bg-grayscale-14 text-secondary", itemRender: { name: "$input", props: { type: "password", placeholder: "Password", autocomplete: "new-password" } } },
        { field: "Email", title: "Email", span: 24, className: "bg-grayscale-14 text-secondary", itemRender: { name: "$input", props: { placeholder: "Email" } } },
        { field: "RoleIds", title: "RoleIds", span: 24, className: "bg-grayscale-14 text-secondary", itemRender: { name: "$select", options: selectorOptions, props: { multiple: true } } },
      ],
      rules: {
        Name: [{ required: true }],
        Email: [{ required: true, message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
        Account: [{ required: true }],
        Password: [{
          required: false,
          validator: (params) => {
            if (params.itemValue) {
              const regex = new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)");
              if (!regex.test(params.itemValue)) return new Error("須8碼以上含大小寫英文、數字");
            } else if (!params.data.Id) return new Error("新用戶須設定密碼");
          },
        }],
        RoleIds: [{ required: true, validator: (params) => { if (!params.itemValue?.length) return new Error("須選擇至少一個以上的角色"); } }],
      }
    };
    const itemData: any = ref({});
    const newItemModal = reactive({
      visible: false,
      show(id: any) {
        if(id) {
          model?.dispatch("user/find", id).then((data)=>{
            data.Password = undefined;
            itemData.value = data;
            newItemModal.visible = true;
          });
        }
        else {
          itemData.value = {};
          newItemModal.visible = true;
        }
      },
      submit(data: any) {
        console.log(data);
        if(data.Id) newItemModal.update(data);
        else newItemModal.insert(data);
      },
      async insert(item: any) {
        try {
          const temp = await model?.dispatch("user/insert", item);
          newItemModal.visible = false;
          grid.value.reload();
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      },
      async update(item: any) {
        try {
          const temp = await model?.dispatch("user/update", item)
          selectedRow.value = temp;
          newItemModal.visible = false;
          grid.value.reload();
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })

    const removeModal = reactive({
      visible: false,
      row: {} as any,
      show() {
        removeModal.visible = true;
        removeModal.row = selectedRow.value!;
      },
      async submit(item: any) {
        try {
          await model?.dispatch("user/delete", item.Id);
          selectedRow.value = null;
          removeModal.visible = false;
          grid.value.reload();
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })

    const onGridCellClick = ({ row, column }: { row: any, column: GridColumnOptions }) => {
      
      selectedRow.value = row;
    }
    watch(() => model?.state["user"]["reloadUI"], value => {
      if (!value) return;
      grid.value.reload();
      model!.state["user"]["reloadUI"] = false;
    })

    onMounted(async () => {
      
      delete model?.enums.UserStatus['Other']
      let status: any = model?.enums.UserStatus;
      userStatus.value = Object.values(status).map((e: any) => { return { label: e.Name, value: e.Value } })
      // Object.values(model!.dispatch("role/query")).map(e => { return { label: e.Name, value: e.Id } }) }
      selectorOptions.value = await model?.dispatch("role/query").then((data)=>{ 
        // console.log("ROLE", data);
        let optionData = data.map((e: any) => { return { label: e.Name, value: e.Id } })
        // console.log("ROLE", optionData);
        return optionData;
      })
      tabs.value = [
        { name: "USER", href: "/user", current: true, permission: permissions.includes('User') },
        { name: "ROLE", href: "/role", current: false, permission: permissions.includes('Role')},
        { name: "ACTION LOG", href: "/action-log", current: false, permission: permissions.includes('ActionLog')}
      ].filter((tab: any) => {
        return tab.permission === true;
      });
    })
    return {
      grid,
      gridOptions,
      selectedRow,
      onGridCellClick,
      formOptions,
      onSearch,
      newItemModal,
      removeModal,
      itemData,
      selectorOptions,
      tabs,
      toolbar: {
        canCreate: true,
      },
    };
  },
});
