
import { defineComponent, ref, watch } from "vue";

import CfModal from "@/components/modal/Main.vue";
import CfButton from "@/components/button/Main.vue";

export default defineComponent({
  name: "ConfirmRemoveModal",
  components: {
    CfModal,
    CfButton,
  },
  props: {
    data: Object,
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const current = ref<any>({});

    watch(() => props.data, value => Object.assign(current.value, value))

    const close = () => {
      emit('close')
    }

    const submit = () => {
      emit("submit", current.value)
    }

    return {
      current,
      close,
      submit
    };
  },
});
