import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50d8adf5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "3xl:container mx-auto" }
const _hoisted_2 = { class: "px-6 lg:px-16" }
const _hoisted_3 = { class: "hidden sm:flex items-center mb-5" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "icon-arrow-up"
}
const _hoisted_6 = {
  key: 1,
  class: "icon-arrow-down"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "text-sm text-tertiary sm:hidden" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentLoader = _resolveComponent("ContentLoader")!
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, colIdx) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "text-tertiary mx-4",
            style: _normalizeStyle({ width: ((col.width || 80) + (col.sortable ? 10 : 0)) + 'px' }),
            key: `col-header-${colIdx}`
          }, [
            (col.sortable)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "text-tertiary hover:text-primary focus:to-primary",
                  onClick: _withModifiers(($event: any) => (_ctx.changeSorting(col)), ["prevent"])
                }, [
                  _createTextVNode(_toDisplayString(col.title) + " ", 1),
                  (_ctx.sortings.some(e => e.column === col.field && e.order === _ctx.SortOrder.Ascending))
                    ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                    : (_openBlock(), _createElementBlock("i", _hoisted_6))
                ], 8, _hoisted_4))
              : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(col.title), 1))
          ], 4))
        }), 128))
      ]),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (row, rowIdx) => {
          return (_openBlock(), _createElementBlock("li", {
            key: 'row-' + row.Id,
            class: "mb-5 sm:mb-0 pb-5 sm:pb-0 border-b border-grayscale-10 sm:border-b-0"
          }, [
            _createElementVNode("a", {
              class: _normalizeClass([{ active: _ctx.selectedRow === rowIdx }, "sm:flex items-center py-4 hover:bg-grayscale-11 rounded-md transition duration-300 ease-in-out hover:cursor-pointer"])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, colIdx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "text-secondary mx-4 transition duration-300 ease-in-out hover:cursor-pointer truncate overflow-ellipsis overflow-hidden",
                  style: _normalizeStyle({ width: (col.width || 80) + 'px' }),
                  key: `col-${rowIdx}-${colIdx}`,
                  onClick: _withModifiers(($event: any) => (_ctx.cellClick(row, rowIdx, col, colIdx)), ["stop"])
                }, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(col.title), 1),
                  (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_ContentLoader, {
                        key: 0,
                        primaryColor: "#1B1B1B",
                        secondaryColor: "#212121",
                        height: "16px"
                      }))
                    : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.processCellValue(row, col)), 1))
                ], 12, _hoisted_8))
              }), 128))
            ], 2)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_v_pagination, {
        modelValue: _ctx.paginationConfig.currentPage,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.paginationConfig.currentPage) = $event)),
          _ctx.updateHandler
        ],
        pages: _ctx.paginationConfig.pages,
        "range-size": 1,
        "active-color": "#1B1B1B"
      }, null, 8, ["modelValue", "pages", "onUpdate:modelValue"])
    ])
  ]))
}